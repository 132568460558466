<template>
  <div class="wl-com">
    <com-box-1></com-box-1>
    <com-box-2></com-box-2>
    <com-box-3></com-box-3>
  </div>
</template>
<script>
import ComBox1 from "./com-box1.vue";
import ComBox2 from "./com-box2.vue";
import ComBox3 from "./com-box3.vue";
export default {
  components: { ComBox1, ComBox2, ComBox3 },
  data() {
    return {};
  },
};
</script>
