<template>
  <div class="com-item">
    <!-- 社区中心 -->
    <!-- <div class="t1">
      {{ $t("community.box1T1") }}
    </div> -->
    <div class="m-title">
      <!-- 欢迎来到PSC社区中心 -->
      {{ $t("community.box1T2") }}
    </div>
    <div class="p">
      <!-- PSC社区是数十万开发人员、技术人员、设计师、用户、HODLers
        和爱好者的家园。 -->
      {{ $t("community.box1P") }}
    </div>
    <div class="butBox">
      <div class="m-but2" @click="uti.goPath(store.link.com.box1But)">
        <!-- 加入社区 -->
        {{ $t("community.box1But") }}
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  width: 100vw;
  height: (382.67 / 3.75vw);
  background: #e5f4ff;
  background-image: url("../../assets/images/v4/com/m/box1Bg.png");
  background-size: (146.33 / 3.75vw) (141.33 / 3.75vw);
  background-position: center (75 / 3.75vw);
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 0 (14 / 3.75vw);
  padding-top: (115 / 3.75vw);
  .p {
    margin-top: (10 / 3.75vw);
    font-size: (13.33 / 3.75vw);
    font-weight: 500;
    text-align: center;
    color: #333333;
  }
  .butBox {
    width: 100%;
    padding: 0 auto;
    text-align: center;
  }
  .m-but2 {
    margin: (32 / 3.75vw) auto;
  }
}
</style>
