<template>
  <div class="com-item">
    <div class="m-title4">
      <!-- 为什么要参与？ -->
      {{ $t("community.box2T") }}
    </div>
    <div class="ul">
      <div
        class="m-item3"
        v-for="(item, index) in $t('community.box2ItemList')"
        :key="index"
      >
        <div class="img">
          <img
            :src="require(`../../assets/images/v4/com/box2${index + 1}.png`)"
            alt=""
          />
        </div>
        <div class="t">{{ item.title }}</div>
        <div class="link"></div>
        <div class="p">
          {{ item.text }}
        </div>
        <div class="more">
          <a
            href="javascript:;"
            @click="uti.goPath(store.link.com.box2List[index])"
          >
            {{ item.ft }}
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-arrowRight"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.com-item {
  padding-top: (32 / 3.75vw);
  box-sizing: border-box;
  text-align: center;
  padding-bottom: (32 / 3.75vw);
  .ul {
    // margin-top: 100px;
    width: 100%;
    .m-item3 {
      // margin: 0 auto;
      .t {
        margin-top: (0 / 3.75vw);
      }
    }
  }
}
</style>
