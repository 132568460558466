<template>
  <div class="home-box">
    <home-box-8></home-box-8>
  </div>
</template>

<script>
import homeBox8 from "../home/home-box8.vue";
export default {
  components: { homeBox8 },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped></style>
